<template>
  <div>
    <a-modal v-model="modalVisible" title="人员列表" :width="700" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">关闭</a-button>
      </template>
      <div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="userid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
          <span slot="status" slot-scope="text" class="status-view">
            <span class="dot" :class="{'light': text=='1'}"></span>
            <span class="text">{{text=='1' ? '启用' : '未启用'}}</span>
          </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { getUserListByCondition } from 'A/xtpz.js'
import {mapState} from "vuex";
export default {
  props: {
    visible: {
      default: false
    },
    userdepid: {
      default: ''
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      modalVisible: false,
      tableLoading: false,
      tableColumns: [
        {
          title: '账号',
          dataIndex: 'useraccount',
          key: 'useraccount'
        },
        {
          title: '姓名',
          dataIndex: 'username',
          key: 'username'
        },
        {
          title: '所属机构',
          dataIndex: 'userdepname',
          key: 'userdepname'
        },
        {
          title: '电子邮件',
          dataIndex: 'email',
          key: 'email'
        },
        {
          title: '用户状态',
          dataIndex: 'status',
          key: 'status',
          sorter: (a, b) => a.status - b.status,
          scopedSlots: { customRender: 'status' }
        },
      ],
      tableData: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 5,
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
    }),
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    // this.getTableData();
  },
  methods: {
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        userdepid: this.userdepid,
        usertypes:this.userInfo.usertype,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getUserListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
  }
}
</script>